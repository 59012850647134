import 'intersection-observer'
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

Vue.use(VueMeta);
Vue.use(VueRouter);

var router = new VueRouter({
  routes: [
    {
      path: '/index.html',
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/',
      component: () => import('@/components/Index.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/index',
      component: () => import('@/components/Index.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/pagosenlinea',
      component: () => import('@/components/panelPagosOnline.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/pagosencajero',
      component: () => import('@/components/panelPagosCajero.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/pagosenbancos',
      component: () => import('@/components/panelPagosBancos.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/pagosentesoreria',
      component: () => import('@/components/panelPagosTesoreria.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/gobinet',
      component: () => import('@/components/gobinet.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/noticias',
      component: () => import('@/components/panelNoticias.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/noticias/:noticia',
      component: () => import('@/components/panelEmbedNoticia.vue'),
      meta: { 
        requiresAuth: false
      }
    }
  ],
  mode: "history"
});

router.beforeEach((to, from, next) => {
  if(to.path == '/index.html'){
    next({
      path: '/index'
    })
  }else{
    next();
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
