<template>
  <v-app>
    <v-content>
      <sipremInfo/>
    </v-content>
  </v-app>
</template>

<script>
import sipremInfo from '@/components/sipremInfo.vue'
export default {
  name: 'App',
  components: {
    sipremInfo
  },
  data: () => ({
    
  }),
};
</script>
