<template>
  <div>
    <a class='flotante' :href='sendMsgWhatsApp' v-if="isMobile"><img src='icons/whatsapp3.png' width="70px" border="0"/></a>
    <v-app-bar class="nav-bar-siprem" app color="rgba(0,36,77,.8)" elevation="12" v-if="this.$router.currentRoute.path != '/gobinet'">
      <a class="text-left img-bar" @click="showPageIndex" v-if="this.$router.currentRoute.path != '/' && this.$router.currentRoute.path != '/index' && this.$router.currentRoute.path != '/index.html'"><img src="imgs/sipremlogoblanco.png" width="66px" /></a>
      <img class="text-left img-bar" src="imgs/sipremlogoblanco.png" width="66px" v-if="this.$router.currentRoute.path == '/' || this.$router.currentRoute.path == '/index' || this.$router.currentRoute.path == '/index.html'" />
        
      <v-menu :open-on-hover="true" :offset-y="true" >
        <template v-slot:activator="{ on }">
          <v-btn color="success" style="opacity: 100;" dark v-on="on" class="text-left bar-content" v-if="!isMobile"> <span>Medios de Pago</span> </v-btn>
          <v-btn color="success" style="opacity: 100;" dark v-on="on" class="text-left bar-content" v-if="isMobile"> <span>Medios <br>de Pago</span> </v-btn>
        </template>
        <v-list>
          <v-list-item @click="showPanelPagoEnLinea" :disabled="this.$router.currentRoute.path == '/pagosenlinea'"> En Línea </v-list-item>
          <v-list-item @click="showPanelPagoCajero" :disabled="this.$router.currentRoute.path == '/pagosencajero'"> Kiosco de Pago </v-list-item>
          <v-list-item @click="showPanelPagoTiendasBancos" :disabled="this.$router.currentRoute.path == '/pagosenbancos'"> Bancos y Tiendas </v-list-item>
          <v-list-item @click="showPanelPagoTesoreria" :disabled="this.$router.currentRoute.path == '/pagosentesoreria'"> Fuera de Oficina </v-list-item>
        </v-list>
      </v-menu>
      <v-btn color="#EA3BB9" style="opacity: 100;" dark class="text-left bar-content" href="https://gobinet.mx" target="_blank" v-if="isMobile && this.$router.currentRoute.path != '/pagosenlinea'"> <span>Trámites <br>en Línea</span> </v-btn>
      <v-btn color="#EA3BB9" style="opacity: 100;" dark class="text-left bar-content" href="https://gobinet.mx" target="_blank" v-if="!isMobile && this.$router.currentRoute.path != '/pagosenlinea'"> <span>Trámites en Línea</span> </v-btn>
      <v-spacer></v-spacer>
      <transition name="slide-vertical">
      <v-toolbar-title class="text-right bar-content" style="min-height: 90px; vertical-align: sub;" v-if="$vuetify.breakpoint.mdAndUp && isShowPhoneNavBar">
        <span style="color:#FFFFFF; font-family:arial-rounded-mt-bold;">Pide una demostración</span><br>
        <v-btn style="font-family: 'arial-rounded-mt-bold'; font-size: 120%;" rounded color="#E49500" dark :href="sendMsgWhatsApp" target="_blank"><v-icon size="27">mdi-phone</v-icon> 729 964 5819</v-btn><br>
      </v-toolbar-title>
      </transition>
    </v-app-bar><br>
    
    <router-view/>

    <v-dialog fullscreen v-model="isShowDialogRevista">
      <v-card align="center" justify="center" width="100%" color="rgba(20,20,20,.8)">
        <flipbook align="center" justify="center" :class="flipbook" :pages="$vuetify.breakpoint.smAndUp ? imgsRevistaSm : imgsRevistaSm" v-slot="flipbook" v-if="isShowRevista" >
          <center>
            <v-btn rounded color="#005189" @click="flipbook.flipLeft"><img width="30px" src="icons/previus.png"/></v-btn>
            <v-btn rounded color="#00406D" @click="hideRevista"><img width="30px" src="icons/close.png"/></v-btn>
            <v-btn rounded color="#005189" @click="flipbook.flipRight"><img width="30px" src="icons/next.png"/></v-btn>
          </center>
        </flipbook>
      </v-card>
    </v-dialog>

    <!-- contacto -->
    <div id='section-contact' v-intersect="{ handler: onShowSectionContacto, options: { threshold: 0.9 } }"><br><br>
    <transition name="slide-fade">
      <div v-if="isShowContacto">
      729 964 5819 <br> info@siprem.com.mx
      </div>
    </transition><br>
    <transition name="slide-fade">
    <v-btn v-if="isShowContacto" class="ma-2" rounded color="success" :href='sendMsgWhatsApp'>Enviar whatsapp</v-btn>
    </transition>
    </div>

    <!--footer xl--><!--v-if="$vuetify.breakpoint.xlOnly && this.$router.currentRoute.path != '/gobinet'"-->
    <v-row class="footer-page border-bottom" id="contacto">
      <v-col xl="5" lg="5" md="6" cols="12" class="text-left" :style="paddingFooter1">
        <img src="imgs/sipremlogoblanco.png" width="80" class="icon-footer">
        <p class="text-direccion">info@siprem.com.mx</p>
        <p class="text-direccion">729 964 5819(Whatsapp)</p>
      </v-col>
      <v-col xl="2" lg="2" md="3" cols="12" class="text-left" :style="paddingFooter2">
        <p class="text-title-footer">Acerca de</p>
        <a @click="showPanelPagoEnLinea" v-if="this.$router.currentRoute.path != '/pagosenlinea'"><p class="text-direccion">En Línea</p></a>
        <a @click="showPanelPagoCajero" v-if="this.$router.currentRoute.path != '/pagosencajero'"><p class="text-direccion">Kiosco de pago</p></a>
        <a @click="showPanelPagoTiendasBancos" v-if="this.$router.currentRoute.path != '/pagosenbancos'"><p class="text-direccion">Bancos y tiendas</p></a>
        <a @click="showPanelPagoTesoreria" v-if="this.$router.currentRoute.path != '/pagosentesoreria'"><p class="text-direccion">Fuera de oficina</p></a>
      </v-col>
      <v-col xl="2" lg="3" md="3" cols="12" class="text-left" :style="paddingFooter2">
        <p class="text-title-footer">Link rapidos</p>
        <a @click="dialog1 = true"><p class="text-direccion">Terminos y condiciones</p></a>
        <a @click="dialog1 = true"><p class="text-direccion">Politicas de privacidad</p></a>
        <a @click="dialog1 = true"><p class="text-direccion">Donde encontrarnos</p></a>
      </v-col>
    </v-row>
    <v-row class="footer-page-second-section">
        <v-col xl="5" cols="12" class="text-left" :style="paddingFooter3"><!--padding-left:380px;-->
          <p class="text-direccion">© 2023 Reew Technologies S.A.S. de C.V.</p>
          <v-spacer></v-spacer>
        </v-col>
        <v-col>
          <a href="#" target="_blank" style="text-decoration:none;" > <v-icon large color="white" style="margin-top:13px;">mdi-facebook</v-icon></a>
        </v-col>
    </v-row>
    <textarea id="email" v-show="false">info@siprem.com.mx</textarea>


    <!------------- v-dialogs -------------->

    <v-dialog v-model="dialog1" max-width="450">
      <v-card>
        <v-card-title class="headline">Terminos y condiciones</v-card-title>
        <v-card-text> <!--some text--> </v-card-text>

        <!--<v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog1 = false" > Acepto </v-btn>
          <v-btn color="green darken-1" text @click="dialog1 = false" > No acepto </v-btn>
        </v-card-actions>-->
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" max-width="450">
      <v-card>
        <v-card-title class="headline">Terminos y condiciones</v-card-title>
        <v-card-text> <!--some text--> </v-card-text>

        <!--<v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog2 = false" > Disagree </v-btn>
          <v-btn color="green darken-1" text @click="dialog2 = false" > Agree </v-btn>
        </v-card-actions>-->
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog3" max-width="450">
      <v-card>
        <v-card-title class="headline">Terminos y condiciones</v-card-title>
        <v-card-text> <!--some text--> </v-card-text>

        <!--<v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog3 = false" > Disagree </v-btn>
          <v-btn color="green darken-1" text @click="dialog3 = false" > Agree </v-btn>
        </v-card-actions>-->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import Flipbook from 'flipbook-vue'
  export default {
    computed: {
      paddingFooter1: function () {
        var paddingLeft=null;
        var paddingTop="padding-top:70px;";
        if(this.$vuetify.breakpoint.xlOnly){
          paddingLeft = "padding-left:380px; ";
        }else if(this.$vuetify.breakpoint.lgOnly){
          paddingLeft = "padding-left:200px; ";
        }else if(this.$vuetify.breakpoint.mdOnly){
          paddingLeft = "padding-left:100px; ";
        }else if(this.$vuetify.breakpoint.smAndDown){
          paddingLeft = "padding-left:25px; ";
        }
        return paddingLeft + paddingTop;
      },
      paddingFooter2: function () {
        var paddingLeft=null;
        var paddingTop=null;
        if(this.$vuetify.breakpoint.xlOnly){
          paddingLeft = "padding-left:0px; ";
          paddingTop = "padding-top:70px;";
        }else if(this.$vuetify.breakpoint.lgOnly){
          paddingLeft = "padding-left:0px; ";
          paddingTop = "padding-top:70px;";
        }else if(this.$vuetify.breakpoint.mdOnly){
          paddingLeft = "padding-left:0px; ";
          paddingTop = "padding-top:70px;";
        }else if(this.$vuetify.breakpoint.smAndDown){
          paddingLeft = "padding-left:25px; ";
          paddingTop = "padding-top:10px;";
        }
        return paddingLeft + paddingTop;
      },
      paddingFooter3: function() {
        var paddingLeft=null;
        if(this.$vuetify.breakpoint.xlOnly){
          paddingLeft = "padding-left:380px; ";
        }else if(this.$vuetify.breakpoint.lgOnly){
          paddingLeft = "padding-left:200px; ";
        }else if(this.$vuetify.breakpoint.mdOnly){
          paddingLeft = "padding-left:100px; ";
        }else if(this.$vuetify.breakpoint.smAndDown){
          paddingLeft = "padding-left:25px; ";
        }
        return paddingLeft;
      },
      flipbook: function() {
        var classFlipbook = null;
        if(this.$vuetify.breakpoint.xlOnly){
          classFlipbook = "flipbook-xl";
        }else if(this.$vuetify.breakpoint.lgOnly){
          classFlipbook = "flipbook-lg";
        }else if(this.$vuetify.breakpoint.mdOnly){
          classFlipbook = "flipbook-md";
        }else if(this.$vuetify.breakpoint.smAndDown){
          classFlipbook = "flipbook-smAndDown";
        }
        return classFlipbook;
      }
    },
    components: {
      Flipbook
    },
    data() {
      return {
        dialog1:false,
        dialog2:false,
        dialog3:false,
        isMobile: false,
        isShowDialogRevista:false,
        isShowRevista:false,
        isShowPhoneNavBar: true,
        isShowContacto: false,
        imgsRevistaSm:[
                      null,
                      'imgs/revista/revista_sm/sipremrgb_portada.png',
                      'imgs/revista/revista_sm/sipremrgb_1.png',
                      'imgs/revista/revista_sm/sipremrgb_2.png',
                      'imgs/revista/revista_sm/sipremrgb_3.png',
                      'imgs/revista/revista_sm/sipremrgb_4.png',
                      'imgs/revista/revista_sm/sipremrgb_5.png',
                      'imgs/revista/revista_sm/sipremrgb_6.png',
                      'imgs/revista/revista_sm/sipremrgb_7.png',
                      'imgs/revista/revista_sm/sipremrgb_8.png',
                      'imgs/revista/revista_sm/sipremrgb_9.png',
                      'imgs/revista/revista_sm/sipremrgb_10.png',
                      'imgs/revista/revista_sm/sipremrgb_11.png',
                      'imgs/revista/revista_sm/sipremrgb_12.png',
                      'imgs/revista/revista_sm/sipremrgb_13.png',
                      'imgs/revista/revista_sm/sipremrgb_14.png',
                      'imgs/revista/revista_sm/sipremrgb_contrap.png',
                    ],
        imgsRevista:[
                      null,
                      'imgs/revista/sipremrgb_portada.png',
                      'imgs/revista/sipremrgb_1.png',
                      'imgs/revista/sipremrgb_2.png',
                      'imgs/revista/sipremrgb_3.png',
                      'imgs/revista/sipremrgb_4.png',
                      'imgs/revista/sipremrgb_5.png',
                      'imgs/revista/sipremrgb_6.png',
                      'imgs/revista/sipremrgb_7.png',
                      'imgs/revista/sipremrgb_8.png',
                      'imgs/revista/sipremrgb_9.png',
                      'imgs/revista/sipremrgb_10.png',
                      'imgs/revista/sipremrgb_11.png',
                      'imgs/revista/sipremrgb_12.png',
                      'imgs/revista/sipremrgb_13.png',
                      'imgs/revista/sipremrgb_14.png',
                      'imgs/revista/sipremrgb_contrap.png',
                    ],
        sendMsgWhatsApp:"https://api.whatsapp.com/send?phone=+527299645819&text=Hola buenas tardes, me gustaría recibir más información de su servicio."
      }
    },
    created() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    methods: {
      onShowSectionContacto(entries){
        this.isShowContacto = entries[0].isIntersecting;
      },
      hideRevista(){/* Este método se manda ha llamar cuando se da click en el boton "X" (cerrar revista) */
        this.isShowDialogRevista=false;
      },
      showRevista(){/* Este método se manda ha llamar cuando se da click en la imagen(portada de revista informativa), o el boton(ver revista) */
        this.isShowDialogRevista=true;
        setTimeout(() => this.isShowRevista=true, 100);
      },
      onShowFooter(entries){/* Este método se manda ha llamar cuando el pie de página esta en pantalla*/
        this.isShowPhoneNavBar = !entries[0].isIntersecting;
      },
      showPageIndex(){/* Este método se manda ha llamar cuando se da click en el titulo ó imagen "Pago en linea" */
        this.$router.push('/');
      },
      showPanelPagoEnLinea(){/* Este método se manda ha llamar cuando se da click en el titulo ó imagen "Pago en linea" */
        this.$router.push('/pagosenlinea');
      },
      showPanelPagoCajero(){/* Este método se manda ha llamar cuando se da click en el titulo ó imagen "Pago en kiosco de pago" */
        this.$router.push('/pagosencajero');
      },
      showPanelPagoTiendasBancos(){/* Este método se manda ha llamar cuando se da click en el titulo ó imagen "Pago en bancos y tiendas" */
        this.$router.push('/pagosenbancos');
      },
      showPanelPagoTesoreria(){/* Este método se manda ha llamar cuando se da click en el titulo ó imagen "Pago fuera de oficina" */
        this.$router.push('/pagosentesoreria');
      }
    },
  }
</script>
<style>
/* Se importan fuentes */
@font-face{
  font-family: "arial-rounded-mt-std";
  src:url(fonts/arial-rounded-mt-std.otf);
}
@font-face{
  font-family: "arial-rounded-mt-light";
  src:url(fonts/arial-rounded-mt-light.ttf);
}
@font-face{
  font-family: "arial-rounded-mt-bold";
  src:url(fonts/arial-rounded-mt-bold.ttf);
}
@font-face{
  font-family: "arial-rounded-mt";
  src:url(fonts/arial-rounded-mt.ttf);
}
.card-carrousel{
  border-radius: 15px; 
  box-shadow: 5px 7px 7px 5px #7C7C7C;
}
.text-card-gobinet{
  padding-top:10px;
  padding-right:10px;
  padding-bottom:10px;
}
.img-gobinet{
  margin:20px;
}
.img-principal{
  border-radius: 20px;
  box-shadow: 4px 4px 15px 1px #7C7C7C;
}
.card-gobinet{
  background: linear-gradient(90deg, rgba(175,46,174,1) 0%, rgba(64,18,114,1) 100%);
}
.text-white{
  font-size:13px;
  color:#FFFFFF;
}
.text-padding{
  padding-left:15px;
  padding-right:15px;
}
.txt-supposition{
  background: linear-gradient(90deg, rgba(28,81,141,.4) 0%, rgba(0,57,120,.8) 100%);
  width: 100%;
  height: 60px;
  padding-top:10px;
  position:absolute;
  bottom:-10px;
}
#section-contact {
  background-image:
  linear-gradient(to bottom, rgba(0,36,77,.8), rgba(0,36,77,.5)),
  url('imgs/bussines.jpg');
  width: 100%;
  height: 500px;
  background-size: cover;
  color: white;
  padding: 20px;
  text-align:center;
  font-size: 30px;
  font-weight: bold;

}
.text-facts{
  font-weight: bold;
  font-size: 18px;
  color:#b6b8d1;
}
.subtitle-facts{
  font-family: 'arial-rounded-mt-std';
  font-size: 30px;
  color:#6d71a4;
}
.title-facts{
  font-family: 'arial-rounded-mt-std';
  font-size: 50px;
  color:#2d357d;
}
.numbers-facts{
  font-family: 'arial-rounded-mt-std';
  font-size: 60px;
  color:#ed7359;
}
.border-bottom{
  border-right: 0px;
  border-top: 0px;
  border-bottom: 0.5px; 
  border-style: solid;
  border-color:#B7B7B7;
}
.footer-page{
  background-color: #00244D; 
  min-height: 220px;
}
.footer-page-second-section{
  background-color: #00244D;
}
.flotante {/* Se aplica al link que contiene el icono "whatsapp" */
  display:scroll;
  position:fixed;
  bottom:20px;
  right:0px;
}
.button-telefono{/* Se aplica al boton de contacto(telefono) que se encuentra en el pie de página */
  margin-left:20px;
}
.text-button-telefono{/* Se aplica al texto del boton de contacto(telefono) */
  font-family: 'arial-rounded-mt-bold'; 
  font-size: 220%; 
}
.cards-xl{/* Se aplica a las v-card dentro de los elementos carousel en resolución xl*/
  margin-top:20px;
}
.cards-lgAnDown{/* Se aplica a las v-card dentro de los elementos carousel en resolución lgAnDown*/
  margin-top:60px;
}
.cards{/* Se aplica a las v-card de la sección beneficios en la vista principal(index.vue)*/
  margin-top: 10px; 
  margin-bottom:10px;
}
.col-beneficio-idx{/* Se aplica en cada columna que contenga un beneficio del sistema(solo en la vista index)*/
  min-height: 240px;
  margin-top: 40px;
}
.col-beneficio{/* Se aplica en cada columna que contenga un beneficio del sistema(excepto en la vista index)*/
  min-height: 150px;
}
.col-caracteristicas-idx{/* Se aplica en cada columna que contenga una caracteristica(solo en la vista index) */
  min-height: 280px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.col-caracteristicas{/* Se aplica en cada columna que contenga una caracteristica(excepto en la vista index) */
  min-height: 240px;
}
.col-caracteristicas-sm{/* Se aplica en cada columna que contenga una caracteristica(excepto en la vista index) */
  min-height: 260px;
}
.col-medios-pago{/* Se aplica en cada columna que contenga un medio de pago */
  min-height: 380px;
}
.dividers{/* Se aplica a los elementos v-divider */
  margin-bottom: 20px;
}
.icons{/* Se aplica a todos los iconos */
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left:5px;
}
.img-bar{/* Se aplica al icono de la barra de navegación */
  margin-top: 25px;
}
.nav-bar-siprem{/* Se apica al elemento v-app-bar*/
  min-height: 90px;
}
.bar-content {/* Se aplica a los elementos que contiene la barra de navegación */
  margin-top: 25px;
  margin-left: 20px;
}
.title-card{/* Se aplica en todos los titulos de v-card */
  font-family: "arial-rounded-mt";
  font-size: 125%;
  color: #138100;
}
.text-card{/* Se aplica al texto que contienen las v-card en todas las vistas, excepto la principal(index.vue) */
  font-family: "arial-rounded-mt";
  color: #5A5A5A;
  margin-top: 5px;
  margin-bottom: 10px;
}
.text-carousel{/* Se aplica a el texto descriptivo que contienen los carousel */
  margin-bottom:10px;
  font-family: 'arial-rounded-mt-bold';
  font-size: 140%;
  color: #FFFFFF;
}
.text-medios-pago{/* Se aplica a el texto que se encuentra en la parte inferior de las imagenes "Medios de Pago" */
  font-family: 'arial-rounded-mt';
  font-size: 125%;
}
.text-img-medios-pago{/* Se aplica a el texto que se encuentra en la parte superior de las imagenes "Medios de Pago" */
  font-family: "arial-rounded-mt";
  font-size: 125%;
  color: #138100;
}
.text-content-card{/* Se aplica al texto que contienen las v-card en la página principal(index.vue) */
  font-family: "arial-rounded-mt";
  color: #5A5A5A;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 5px;
  margin-bottom: 10px;
}
.text-content{
  margin-bottom: 10px;
}
.text-description{ /* Se aplica a la descripción que se encuantra debajo del titulo de cada página */
  color: #2196F3;
}
.text-description-index{ /* Se aplica a la descripción que se encuantra debajo del titulo de cada página */
  color: #2196F3;/*68D805*/
}
.text-sm{/* Se aplica al texto del pie de página en resolución sm */
  font-family: "arial-rounded-mt-bold";
  margin-left: 20px;
  font-size: 130%;
  color: #FFFFFF;
}
.icon-footer{
  /*margin-top:50px;*/
}
.text-direccion{
  font-size: 100%;
  margin-top:20px;
  color: #FFFFFF;
}
.text-title-footer{
  font-weight: bold;
  font-size: 100%;
  margin-top:20px;
  color: #FFFFFF;
}
.text-md{/* Se aplica al texto que contiene el pie de página(en resolución md) */
  font-family: "arial-rounded-mt-bold";
  margin-left: 20px;
  font-size: 180%;
  color: #FFFFFF;
}
.text-lg{/* Se aplica al texto que contiene el pie de página(en resolución lg) */
  font-family: "arial-rounded-mt-bold";
  margin-left: 20px;
  font-size: 200%;
  color: #FFFFFF;
}
.text-xl{/* Se aplica al texto que contiene el pie de página(en resolución xl) */
  margin-left: 20px;
  font-family: "arial-rounded-mt-bold";
  font-size: 310%;
  color: #FFFFFF;
}
.text-footbar{/* Se aplica a todo el texto que contiene el pie de página */
  font-family: "arial-rounded-mt";
}
.title-beneficios{/* Se aplica en el div que contiene el subtitulo beneficios */
  min-height: 50px;
}
.title-caracteristicas{/* Se aplica en el div que contiene el subtitulo caracteristicas */
  min-height: 70px;
}
.title-medios-pago{/* Se aplica en el div que contiene el subtitulo medios de pago */
  margin-top: 3%;
  min-height: 60px;
}
.title-page-movil{ /* Se aplica en los titulos de cada vista para dispositivos moviles */
  font-family: "arial-rounded-mt-bold";
  font-size: 160%;
  color: #0a499b;
}
.title-page-movil-index{ /* Se aplica en los titulos de cada vista para dispositivos moviles */
  font-family: "arial-rounded-mt-bold";
  font-size: 160%;
  color: #FFFFFF;
}
.title-page{ /* Se aplica en el titulo principal de cada vista */
  font-family: "arial-rounded-mt-bold";
  font-size: 290%;
  color: #0a499b;
}
.title-page-index{ /* Se aplica en el titulo principal de cada vista */
  font-family: "arial-rounded-mt-bold";
  font-size: 290%;
  color: #FFFFFF;
}
.text-slogan{/* Se aplica en el row que contiene el slogan en cada vista*/
  font-family: "Proxima nova";
  font-style: italic;
  font-size: 150%;
  color: #5A5A5A;
  margin-top: 3%;
  margin-bottom: 3%;
}
.text-subtitles{/* Se aplica a los subtitulos de todas las vistas */
  font-family: "arial-rounded-mt-bold";
  font-size: 150%;
  color: #0C469E;
}
.row-margin{/* Se aplica los v-row de las secciones beneficios y caracteristicas de todas las vistas excepto la vista principal(index)*/
  margin-top: 40px;
  margin-bottom: 40px;
}
.section-one-xl{/* Se aplica a todas las vistas desde el inicio hasta la sección beneficios(en resolución xl)*/
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 15%;
  margin-left: 15%;
}
.section-one-lgAndDown{/* Se aplica a todas las vistas desde el inicio hasta la sección beneficios(en resolución lgAndDown)*/
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 3%;
  margin-right: 3%;
}
.section-one-xl-index{/* Se aplica a todas las vistas desde el inicio hasta la sección beneficios(en resolución xl)*/
  margin-top: 10%;
  margin-bottom: 5%;
  margin-right: 15%;
  margin-left: 15%;
}
.section-one-lgAndDown-index{/* Se aplica a todas las vistas desde el inicio hasta la sección beneficios(en resolución lgAndDown)*/
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 3%;
  margin-right: 3%;
}
.section-two-xl{/* Se aplica a todas las vistas desde el inicio hasta la sección beneficios(en resolución xl)*/
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 15%;
  margin-left: 15%;
}
.section-two-lgAndDown{/* Se aplica a todas las vistas desde el inicio hasta la sección beneficios(en resolución lgAndDown)*/
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 3%;
  margin-right: 3%;
}
.section-caracteristicas-lgAndDown{/* Se aplica a todas las vistas en la sección características en(resolución lgAndDown)*/
  margin-right: 0%;
  margin-left: 0%;
}
.section-caracteristicas-xl{/* Se aplica a todas las vistas en la sección características(en resolución xl)*/
  margin-right: 10%;
  margin-left: 10%;
}
.section-slogan{/* Se aplica en el div de la sección que contiene el slogan en cada página*/
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 15%;
  margin-left: 15%;
}
.section-slogan-index{/* Se aplica en el div de la sección que contiene el slogan en cada página*/
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 15%;
  margin-left: 15%;
}
.section-slogan-index-sm{/* Se aplica en el div de la sección que contiene el slogan en cada página*/
  margin-top: 100px;
  margin-bottom: 5%;
  margin-right: 15%;
  margin-left: 15%;
}
.section-medios-pago{/* Se aplica al div que contiene información sobre "Medios de Pago"*/
  margin-top: 3%;
  margin-bottom: 5%;
}

/* Estilos usados para la transición horizontal (Titulos) */
.slide-fade-enter-active {
  transition: all 0.9s ease;
}
.slide-fade-leave-active {
  transition: all 0.9s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateY(100px);
  opacity: 0;
}

/* Estilos usados para la transición vertical (v-cards e imagenes) */
.slide-vertical-enter-active {
  transition: all 0.9s ease;
}
.slide-vertical-leave-active {
  transition: all 0.9s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-vertical-enter, .slide-vertical-leave-to{
  transform: translateX(-70px);
  opacity: 0;
}

/* Estilos para el efecto 'esfera', que se utiliza en los iconos de v-cards */
.img-supposition-rounded-2{
  background: linear-gradient(90deg, rgba(28,81,141,1) 0%, rgba(0,57,120,1) 100%);
  box-shadow: 4px 4px 15px 1px #7C7C7C;
  border-radius: 100px;
  font-size:20px;
  padding:20px;
  width: 33%;
  height: 98px;
  position:absolute;
  left: 98px;
  top:-35px;
  text-align:center;
}
.img-supposition-ball-2 {
  display: block;
  width: 100%;
  height: 98px;
  align-items: center;
  background-color: #1B73B8;
  border-radius: 50%;
  box-shadow: inset -25px -25px 40px rgba(10,10,10,.5);
  background-image: -webkit-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%);
  background-image: -moz-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%);  
  background-image: -o-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%);
  background-image: -ms-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%);
  position:absolute;
  left: 0px;
  top:0px;
  text-align:center;
}

/* Estilos usados para el elemento Flipbook, segun la resolución */
.flipbook-xl{
  width: 70vw;
  height: calc(100vh - 50px - 40px);
}
.flipbook-lg{
  width: 70vw;
  height: calc(100vh - 50px - 40px);
}
.flipbook-md{
  width: 70vw;
  height: calc(100vh - 50px - 40px);
}
.flipbook-smAndDown {
  width: 100%;
  height: calc(115vh - 50px - 40px);
}

</style>